import React, { useEffect, useState } from 'react';
import styles from './GuideDescription.module.scss'
import { useParams } from 'react-router-dom';
import { useGetGuideByIdQuery, useGetHeroByHeroNameQuery, useGetHeroDetailsQuery, useGetProfileQuery, useUpdateGuideMutation } from '../../store/api';
import { Tooltip, TextField } from '@mui/material';

export const GuideDescription = () => {
    const { guideId } = useParams<{ guideId?: string }>()
    const guideIdString = guideId ?? ''
    const { data: guides } = useGetGuideByIdQuery(guideIdString)
    const { heroId } = useParams<{ heroId?: string }>()
    const heroIdString = heroId ?? ''
    const { data: hero } = useGetHeroDetailsQuery(heroIdString)
    const { data: profile, isLoading } = useGetProfileQuery()
    const [isOwner, setIsOwner] = useState(false)
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [newTitle, setNewTitle] = useState(guides?.guideTitle);
    const [newDescription, setNewDescription] = useState(guides?.guideDescription);
    const [updateGuide] = useUpdateGuideMutation();
    const handleTitleClick = () => {
        setIsEditingTitle(true);
    };

    const handleDescriptionClick = () => {
        setIsEditingDescription(true);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTitle(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewDescription(e.target.value);
    };

    const handleBlurTitle = () => {
        setIsEditingTitle(false);
        if (newTitle !== guides?.guideTitle) {
            updateGuide({ id: guides?.id, guideTitle: newTitle });
        }
    };

    const handleBlurDescription = () => {
        setIsEditingDescription(false);
        if (newDescription !== guides?.guideDescription) {
            updateGuide({ id: guides?.id, guideDescription: newDescription });
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isEditingTitle) {
                handleBlurTitle();
            } else if (isEditingDescription) {
                handleBlurDescription();
            }
        }
    };

    useEffect(() => {
        setNewDescription(guides?.guideDescription)
        setNewTitle(guides?.guideTitle)
    }, [guides])

    return (
        <div className={styles.page}>
            <div className={styles.topItem}>
                <div className={styles.guide}>
                    {isEditingTitle ? (
                        <TextField
                            value={newTitle}
                            onChange={handleTitleChange}
                            onBlur={handleBlurTitle}
                            onKeyPress={handleKeyPress}
                            autoFocus
                            variant="outlined"
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                        />
                    ) : (
                        <h1 onClick={handleTitleClick}>{guides?.guideTitle}</h1>
                    )}
                    <div className={styles.overflow}>
                        {isEditingDescription ? (
                            <TextField
                                value={newDescription}
                                onChange={handleDescriptionChange}
                                onBlur={handleBlurDescription}
                                onKeyPress={handleKeyPress}
                                autoFocus
                                variant="outlined"
                                size="small"
                                multiline
                                rows={4}
                                sx={{ backgroundColor: 'white' }}
                            />
                        ) : (
                            <h4 onClick={handleDescriptionClick}>{guides?.guideDescription || "No description"}</h4>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.topItem}>
                <div className={styles.hero}>
                    <img src={hero?.img} className={styles.img} />
                    <div className={styles.skills}>
                        {hero?.skills.map((skill) => (<Tooltip title={skill.skillDescription}><img src={skill.img} className={styles.skillImg} /></Tooltip>))}
                    </div>
                </div>
                <div className={styles.heroDescription}>
                    <h1>{hero?.heroName}</h1>
                    <div className={styles.overflow}><h4>Hero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero descriptionHero description</h4></div>
                </div>

            </div>
            <div className={styles.item}>
                <div className={styles.skillBuilds}>
                    <h1>
                        skill builds
                    </h1>
                    <div className={styles.overflow}>
                        {guides?.skillBuilds.map((skillBuild) => (
                            <div className={styles.tableContainer}>
                                <h3>{skillBuild.skillBuildName}</h3>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th className={styles.cell}></th>
                                            {[...Array(18)].map((_, index) => (
                                                <th key={index + 1} className={styles.cell}>{index + 1}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {hero?.skills.map((skill) => (
                                            <tr>
                                                <td>
                                                    <Tooltip title={skill.skillDescription}>
                                                        <img src={skill.img} width={50} />
                                                    </Tooltip>
                                                </td>
                                                {[...Array(18)].map((_, colIndex) => (
                                                    <td className={skillBuild.skillsOrder[colIndex]?.skill == skill.id ? styles.yellow : ''}></td>
                                                ))}
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                                <p>{skillBuild.skillBuildDescription}</p>
                            </div>
                        )
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.skillBuilds}>
                    <h1>
                        stages
                    </h1>
                    <div className={styles.overflow}>
                        {guides?.stages.map((stage) => {
                            return (
                                <div>
                                    <h3>{stage.stageName}</h3>
                                    <div className={styles.stageItems}>
                                        {stage.itemWrappers.map((itemWrapper) => {
                                            return (
                                                <Tooltip title={itemWrapper.itemWrapperExplanation}>
                                                    <img src={itemWrapper.item.img} width={88} height={64} />
                                                </Tooltip>
                                            )
                                        })}
                                    </div>
                                    <p>{stage.stageDescription}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};