import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Attribute, Hero } from './types/Hero.types'
import { CreateGuideBody, Guide, ItemSection, UpdateGuideBody } from './types/Guide.types'
import { CreateProfile, LoggedUserBody, LoginBody, Profile } from './types/Profile.types'


const baseQueryWithReAuth = async (args: any, api: any, extraOptions: any) => {
    const baseQuery = fetchBaseQuery({
        baseUrl: 'https://dota-guides.xyz/api',
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token');
            if (token) {
                headers.set('Authorization', `Token ${token}`);
            }
            return headers;
        },
    });

    const result = await baseQuery(args, api, extraOptions);

    // Check for 401 errors and handle token removal automatically
    if (result.error && result.error.status === 401) {
        localStorage.removeItem('token');
        api.dispatch(dotaApi.util.invalidateTags(['Profile', 'Hero', 'Guide']));
    }

    return result;
};

export const dotaApi = createApi({
    reducerPath: 'api',
    tagTypes: [
        'Hero',
        'Attribute',
        'Item',
        'Guide',
        'Profile',
    ],
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getHeroes: builder.query<Hero[], void>({
            query: () => '/heroes',
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Hero' as const, id })),
                        'Hero',
                    ]
                    : ['Hero'],
        }),

        getAttributes: builder.query<Attribute[], void>({
            query: () => '/attributes',
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Attribute' as const, id })),
                        'Attribute',
                    ]
                    : ['Attribute'],
        }),

        getHeroDetails: builder.query<Hero, string>({
            query: (heroName) => `/heroes/${heroName}`,
            providesTags: ['Hero', 'Guide'],
        }),

        getGuideById: builder.query<Guide, string>({
            query: (id) => `/guides/${id}`,
            providesTags: ['Guide'],
        }),

        getHeroByHeroName: builder.query<Hero[], string>({
            query: (id) => `/heroes/${id}`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Hero' as const, id })),
                        'Hero',
                    ]
                    : ['Hero'],
        }),

        createProfile: builder.mutation<void, CreateProfile>({
            query: (body) => ({
                url: `/auth/register/`,
                method: 'POST',
                body,
            }),
        }),

        login: builder.mutation<LoggedUserBody, LoginBody>({
            query: (body) => ({
                url: `/auth/login/`,
                method: 'POST',
                body,
            }),
            async onQueryStarted(_arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    localStorage.setItem('token', data.token);
                } catch (error) {
                    console.error("Login error:", error);
                }
            },
            invalidatesTags: ['Profile', 'Hero', 'Guide']
        }),

        getProfile: builder.query<Profile, void>({
            //@ts-ignore
            queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
                const token = localStorage.getItem('token');
                if (!token) {
                    return { data: undefined }; // Prevent the query if token isn't available
                }
                const responce = await fetchWithBQ(`/profile/`)
                if (responce.error) {
                    return { data: undefined }
                }
                return responce
            },
            providesTags: ['Profile']
        }),

        logOut: builder.mutation<void, void>({
            query: (body) => ({
                url: `/auth/logout/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile', 'Hero', 'Guide']
        }),

        getItems: builder.query<ItemSection[], void>({
            query: () => '/items',
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Item' as const, id })),
                        'Item',
                    ]
                    : ['Item'],
        }),

        createGuide: builder.mutation<void, CreateGuideBody>({
            query: (body) => ({
                url: `/guides/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Guide']
        }),

        deleteGuide: builder.mutation<void, number>({
            query: (id) => ({
                url: `/guides/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Guide'],
        }),

        updateGuide: builder.mutation<UpdateGuideBody, UpdateGuideBody>({
            query: (body) => ({
                url: `/guides/${body.id}/`,
                method: 'PATCH',
            }),
            invalidatesTags: ['Guide'],
        })
    })
})

export const {
    useGetHeroesQuery,
    useGetAttributesQuery,
    useGetHeroDetailsQuery,
    useGetGuideByIdQuery,
    useGetHeroByHeroNameQuery,
    useCreateProfileMutation,
    useLoginMutation,
    useGetProfileQuery,
    useLogOutMutation,
    useGetItemsQuery,
    useCreateGuideMutation,
    useDeleteGuideMutation,
    useUpdateGuideMutation,
} = dotaApi